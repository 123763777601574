import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
import { OrderBtn } from "../Styles/colors";
import useForm from "../Hooks/useForm";
import { T } from "../Language/translate";

const db = window.firebase.firestore();

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;

const DialogFooter = styled.div`
  box-shadow: 0px -2px 10px 0px grey;
  height: 55px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-top: 5px;
`;

export const UserFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConfirmButton = styled.div`
  margin: 5px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
    background-color: grey;
    pointer-events: none;
  `}
`;

const Notice = styled.span`
  color: red;
  font-size: 14px;
`;

const AddressTxt = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
`;

function phoneValid(value) {
  return !isNaN(value) && value != null && value.trim().length > 0;
}
function Required(value) {
  return value != null && value.trim().length > 0;
}
var session = window.sessionStorage;
var google = window.google;

export function EditForm({ setOpenUser, loggedIn, prop, profile, lang, isGuest }) {
  const [zip, setZip] = useState(profile.zip );
  const [phone, setPhone] = useState(profile.phone );
  const [street, setStreet] = useState(profile.street );
  const [city, setCity] = useState(profile.city );
 
  const autocompleteContainerRef = useRef(null);
  // Form validation
  const initialState = {
    name: profile.name ,
    address: profile.address,
    floor: profile.floor ,
    door: profile.door ,
    houseNumber: profile.houseNumber ,
  };
  const validations = [];
  const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);

  const [Err, setError] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [zipErr, setZipErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [selected, setSelected] = useState(false);
  const [hNumber, setHnumber] = useState(profile.houseNumber);
  const [zipManual, setZipManual] = useState(false); // Track if zip input should be manual

  function showError() {
    if (!(errors.address || errors.zip || errors.phone)) {
      setError(T("52", lang));
    }
    
    phoneValid(phone) ? setPhoneErr("") : setPhoneErr(T("48", lang));
    !Required(values.address)? setAddressErr(T("59", lang)) : setAddressErr("");
    !Required(zip) ? setZipErr(T("47", lang)) : setZipErr(""); // Check for empty ZIP code
     console.log((values.address && (zipManual ? Required(zip) : !isNaN(zip)) && phoneValid(phone) && Required(hNumber)) )
     console.log(hNumber)
  }

  function handleAddition(place) {
    let newZip = "";
    let newStreet = "";
    let newCity = "";
    let newHNumber = "";
  
    place.address_components.forEach((item) => {
      if (item.types.includes('postal_code')) newZip = item.long_name;
      if (item.types.includes('route')) newStreet = item.long_name;
      if (item.types.includes('locality')) newCity = item.long_name;
      if (item.types.includes('street_number')) newHNumber = item.long_name; // Extract house number
      console.log("after select "+newHNumber)
    });
  
    if (newZip) {
      setZip(newZip);
      setZipManual(false); // Zip was set automatically, so disable manual input
    } else {
      setZip(""); // No zip code was set, so clear it
      setZipManual(true); // Allow manual zip input
    }

    setStreet(newStreet);
    setCity(newCity);
    setHnumber(Required(newHNumber)?newHNumber:profile.houseNumber); // Set house number to state
    changeHandler({ target: { name: 'address', value: place.formatted_address } });
    setSelected(true);
    setAddressErr("");
    setError('');
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (autocompleteContainerRef.current) {
        const input = autocompleteContainerRef.current.querySelector('#my-autocomplete');
        if (input) {
          input.focus();
          input.setAttribute('autocomplete', 'new-password');
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  function handleAddressChange(e) {
    changeHandler(e);
    // Reset zip and house number when address changes
    setZip("");
    setSelected(false);
    setZipManual(true); // Enable manual zip code entry
  }
  const handleGuest = () => {
    session.setItem("name", values.name);
    session.setItem("houseNumber", values.houseNumber);
    session.setItem("street", street);
    session.setItem("city", city);
    session.setItem("floor", values.floor);
    session.setItem("door", values.door);
    session.setItem("phone", phone);
    session.setItem("zipCode", zip);
    session.setItem("GuestOk", true);
    console.log("guest handeling")
    setOpenUser();
  };

  const handleSubmit = () => {
    console.log(zip)
    db.collection('zip').doc(String(zip)).get().then((doc) => {
      if (doc.exists) {
        const docRef = db.collection("users").doc(loggedIn.email);
        try {
          docRef.update({
            street: street,
            zipCode: zip,
            city: city,
            houseNumber: values.houseNumber,
            email: loggedIn.email,
            name: loggedIn.displayName,
            phone: phone,
            floor: values.floor,
            door: values.door,
          });
        } catch (e) {
          console.log(e);
        }
        setOpenUser();
      } else {
        setAddressErr(T("50", lang));
      }
    });
  };


  return (
    <>
      <Notice>{zipErr}</Notice>
      <label>
        {T("37", lang)}
        <input
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
          className="form-control"
          name="zip"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          disabled={!zipManual} // Enable only if manual entry is allowed
        />
      </label>

      <label className="label">
        <AddressTxt>{T("142", lang)}</AddressTxt>
        <Notice>{addressErr}</Notice>
        <div ref={autocompleteContainerRef}>
          <Autocomplete
            id="my-autocomplete" // Unique ID
            type="text"
            placeholder=" "
            className="form-control"
            value={values.address}
            name="address"
            onBlur={changeHandler}
            onChange={handleAddressChange} // Use handleAddressChange
            apiKey={"AIzaSyC_NsDrw_bCL2e5UQEiknoOFhwWdKadKtQ"}
            style={{ width: "fit-content(20em)" }}
            onPlaceSelected={(place) => {
              handleAddition(place);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "Fr" },
          
            }}
            autoFocus
          />
        </div>
      </label>

      <label>
        {T("138", lang)}
        <input
          className="form-control"
          name="houseNumber"
          type="text"
          value={values.houseNumber}
          onChange={changeHandler}
          autoComplete="off"
        />
      </label>
      <label>
        {T("39", lang)}
        <input
          className="form-control"
          name="floor"
          type="text"
          value={values.floor}
          onChange={changeHandler}
          autoComplete="off"
        />
      </label>
      <label>
        {T("40", lang)}
        <input
          className="form-control"
          name="door"
          type="text"
          value={values.door}
          onChange={changeHandler}
          autoComplete="off"
        />
      </label>
      {(isGuest || (session.getItem("GuestOk") && loggedIn == null)) && (
        <label>
          {T("34", lang)}
          <input
            className="form-control"
            name="name"
            type="text"
            value={values.name}
            onChange={changeHandler}
            autoComplete="off"
          />
        </label>
      )}
      <Notice>{phoneErr}</Notice>
      <label>
        {T("54", lang)}
        <input
          className="form-control"
          placeholder={T("147", lang)}
          name="phone"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          autoComplete="off"
        />
      </label>
      <DialogFooter>
        <ConfirmButton
                  onClick={() => {
                    !(values.address &&  Required(zip) && phoneValid(phone) && Required(hNumber)) ? showError() : isGuest ? handleGuest() : handleSubmit();
                  }}
        >
          {T("55", lang)}
        </ConfirmButton>
      </DialogFooter>
    </>
  );
}