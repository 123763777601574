import React from "react";
import { useState ,useEffect } from "react";
import styled from 'styled-components';
import { FoodLabel } from "../Menu/FoodGrid";
import { pizzaRed } from "../Styles/colors";
import { Title } from "../Styles/title";
import { QuantityInput } from "./QuantityInput";
import { Remark } from "./Remark";
import { useQuantity } from "../Hooks/useQuantity";
import { Toppings } from "./Toppings";
import { useChoice } from "../Hooks/useChoice";
import { Choices } from "./Choices";
import { OrderBtn } from "../Styles/colors";
import {T} from "../Language/translate";
import { Id } from "../ProjectId";
import { Token } from "../StorageToken";
var _ = require('lodash');
  export const Dialog = styled.div`
  position: fixed;
    width:520px;
    height:auto;
    background-color: white;
    margin:auto;
  
    z-index: 55;
  

  padding:5px;
    display: flex;
    flex-direction: column;
    border-radius:5px;
    // Add this part
    @media (max-width: 700px) {
      
      overflow-y: scroll;
      height:auto;
      width: 100%;
  
      z-index: 55;
    }
    @media(max-height: 800px){ 
      height:600px;
      
      
     }
     @media(max-height: 700px){ 
      height:550px;
      
      
     }
    @media(max-height: 600px){ 
      height:500px;
      
      
     }
     @media(max-height: 500px){ 
      height:400px;
      
      
     }
  `;
  export const DialogShadow = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
  top:0;
  left:0;
    background-color: black;
    opacity: 0.7;
    z-index: 50;
  `;
const DialogBanner = styled.div`
min-height: 200px;
width : 100%;
border-radius : 5px;
margin-bottom : 20px ; 
${({img})=> (img ?`background-image : url(${img});`:`min-height:75px;`)}
background-position: center;
background-size : cover;
image-rendering: crisp-edges;
display: flex;
flex-direction: column;
@media(max-width: 400px){ 
    max-width: 400px; 
   
  }
`;
const Container = styled.div`
position:fixed;
top:10px;
height:560px;
right:0;
width:${(props) => props.width};
overflow-y: scroll;
font-size : 20px;
@media screen and (max-width: 995px) {
  margin-top:50px;
    margin-left:10px;
    width:100%;
}
`;
const InputContainer = styled.div`
  display:flex;
  flex-direction:row;
  gap:5px;
  margin-top:2px;

  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    
   }
  
`;
const Div = styled.div`
&:hover{
  cursor: pointer;
  opacity:0.7;
}
`;

const AddContainer = styled.div`
height:700px;
display: flex;
flex-direction: column;
margin:5px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 50px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
height: auto;
grid-column-gap: 38px;
`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
gap: 10px;

`;

const SearchContainer = styled.div`
display: flex;
flex-direction: column;
overflow-y: scroll;
height:100px;

margin-left:100px;
cursor:pointer;
&:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Input = styled.input`
cursor:pointer;
&:hover{
    cursor: pointer;
    
 }
`;
const Input2 = styled.textarea`

height:100px;
cursor:pointer;
resize: none;
&:hover{
    cursor: pointer;
    
 }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: auto;
padding-bottom:20px;
width:80%;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;

const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Okay = styled.span`
white-space: nowrap;
font-weight:bold;
  margin-left:20px;
  color: green;
  font-size: 16px;
`;
const Label = styled.label`
margin-top:5px;
margin-bottom:5px;
margin-right:10px;
`;
const ButtonContainer = styled.div`
padding-top:30px;
margin-right:20px;
margin-left: auto;
margin-bottom:30px;
display:flex;
flex-direction:row;
`;

const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;
const Hide = styled.div`
display:none;
`;
const DialogBannerName = styled(FoodLabel)`
width:320px;
top : ${({img})=> (img ? `100px`:`40px`)};
font-size : 30px;
padding: 5px 20px;

`;

export const DialogContent = styled(Title)`
overflow: auto;
height: 300px;
padding:10px 20px;
padding-bottom: 40px;

`;
export const DialogFooter = styled.div`
box-shadow : 0px -2px 10px 0px grey;
height: 60px;
display: flex;
justify-content: center;
border-radius:5px;
`;
 const ConfirmButton = styled(Title)`
margin: 10px;
color : white;
height:40px;
width:100%;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: ${OrderBtn};
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const H4 = styled.h4`
margin:5px;
`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
z-index:50;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const HContainer = styled.div`
display:flex;
flex-direction:row;
@media(max-width: 700px){ 
   flex-direction:column;
   
   
  }
`;
const Radio = styled.input`
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
const RadioLabel = styled.label`
white-space:nowrap;
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
const VContainer = styled.div`
display:flex;
flex-direction:column;
@media(max-width: 700px){ 
   flex-direction:column;
   
  }
`;
export const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;
const Limit = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: gray;
  font-size: 12px;
`;
const HGrid = styled.div`
display:grid;
grid-template-columns: 1fr 1fr 1fr 1fr ;
`;
const HDiv = styled.div`
display:flex;
flex-direction:row;
`;

const db = window.firebase.firestore();
var session = window.sessionStorage;
export function getPrice(order){

    return order.Qu * ( order.price + order.toppings.filter(t=> t.checked).reduce((acc,obj)=>{return acc + obj.price},0));
}

function getPrice2(order,x){
    console.log(x,order.price)
  return order.Qu * ( (x==="Méga"? order.price2:(x==="Sénior"||x==="Menu")?order.price1:order.price) + order.toppings.filter(t=> t.checked).reduce((acc,obj)=>{return acc + obj.price},0));
}

function FoodDialogContainer({openFood, setOpenFood, setOrders, orders,setDeliveryFee,loggedIn,promoPrice,
  setPromoPrice,lang,applied,promoCategory,isOrderedit,setOrderEdit,minOrderPrice,
  setMinOrderPrice}){
    
    const foods =  isOrderedit ? openFood : openFood.data();
    console.log(isOrderedit)
    console.log(foods)
    const[remark , setRemark] = useState('');
    const[size,setSize]=useState("Sénior");
    const[size2,setSize2]=useState("Seul");
    const [pizzas, setPizzas] = useState([]);
    const [pizzasList, setPizzaList] = useState([]);
    const [items, setItems] = useState([]);
    const quantity = useQuantity(openFood && openFood.Qu,openFood);
    var allIngredients = foods.ingredient !== null ?  foods.ingredient.split(', ') : [];
    const [allIngredients2,setAllIngredients2] = useState([]);
    const [checkedItems, setCheckedItems] = useState(allIngredients.map(() => true));
    const [checkedItems2, setCheckedItems2] = useState();
    const [Choice, setChoice] = useState('');
    const [Choice2, setChoice2] = useState('Cheese');
    const [drinks, setDrinks] = useState([]);
    const [drinks2, setDrinks2] = useState([]);
    const [selectedDrink1, setSelectedDrink1] = useState('');
    const [selectedPizza1, setSelectedPizza1] = useState('');
    const [selectedDrink2, setSelectedDrink2] = useState('');
    const [selectedPizza2, setSelectedPizza2] = useState('');
    const [selectedDrink3, setSelectedDrink3] = useState('');
    const [selectedPizza3, setSelectedPizza3] = useState('');
    const [selectedDrink4, setSelectedDrink4] = useState('');
    const [sauce1, setSauce1] = useState('');
    const [sauce2, setSauce2] = useState('');
    const [sauce3, setSauce3] = useState('');
    const [viande1, setViande1] = useState('');
    const [viande2, setViande2] = useState('');
    const [viande3, setViande3] = useState('');
    const saucesData = [
      { id: 1, name: 'Sauce Mayo' },
      { id: 2, name: 'Sauce Ketchup' },
      { id: 3, name: 'Sauce Harissa' },
      { id: 4, name: 'Sauce Andlouse' },
      { id: 5, name: 'Sauce Algerienne' },
      { id: 6, name: 'Sauce Samurai' },
      { id: 7, name: 'Sauce Blanche' },
      { id: 8, name: 'Sauce Curry' },
      { id: 9, name: 'Sauce Bigy' },
      // Add more sauces as needed
    ];

    const viandeData = [
      { id: 1, name: 'Viande hachée' },
      { id: 2, name: 'Jambon' },
      { id: 3, name: 'Tenders' },
      { id: 4, name: 'Nuggets' },
      { id: 5, name: 'Merguez' },
      { id: 6, name: 'Cordon bleu' },
      { id: 7, name: 'Kebab' },

      // Add more sauces as needed
    ];


    

    const [toppings, setToppings] = useState([]);

    const choiceRadio = useChoice(openFood.choice);
    const menuCountMap = {
      'Menu Duo': 1,
      'Menu Trio': 3,
      'Menu Quatro': 2,
    };
    const handleDropdownChange = (event, setSauce) => {
      const selectedSauce = event.target.value;
      setSauce(selectedSauce);
    };
    const handleCheckboxChange = (index) => {
      const newCheckedItems = [...checkedItems];
      newCheckedItems[index] = !newCheckedItems[index];
      setCheckedItems(newCheckedItems);
    };

    const uncheckedIngredients = allIngredients.filter((_, index) => !checkedItems[index]);
    const withoutItemMessage = uncheckedIngredients.length === 0 ? null : `Sans ${uncheckedIngredients.join(', ')}`;
   
    const uncheckedIngredients2 = allIngredients2.filter((_, index) => !checkedItems2[index]);
    const withoutItemMessage2 = uncheckedIngredients2.length === 0 ? null : `Sans ${uncheckedIngredients2.join(', ')}`;

    useEffect(()=>{ 
      setPizzas([])
      //logic is here Pizza M , Pizza L
        db.collection('options').where('name','==',foods.options).onSnapshot((data)=>{return  setToppings(_.toArray(data.docs[0].data().extras))});
       //delivery fee always free
        setDeliveryFee('0');
        db.collection('items')
        .where('category', '==', 'Pizza')
        .get()
        .then((data) => {
          const PizzasData = data.docs.map((doc) => doc.data());
          setPizzaList(PizzasData);
        });

     
        if(loggedIn){
        db.collection('users').doc(loggedIn.email).get().then((doc)=>{ 
          
          if(doc.data().city==="Thoury" || doc.data().city ==="Massives"|| doc.data().city ==="Saint-Dyé-sur-Loire") {
            setMinOrderPrice(20)
          }
      if( doc.data().city ==="Lailly-en-Val"  || doc.data().city==="La Ferté-Saint-Cyr" ||doc.data().city==="Mer"|| doc.data().city==="Maslives"|| doc.data().city==="Chambord") {
        setMinOrderPrice(30)
      }
      if(doc.data().city ==="Dhuizon"|| doc.data().city==="Montlivault"){
        setMinOrderPrice(40)
      }
           
         })
        }
        else if(session.getItem("GuestOk")&&loggedIn==null){
          console.log(session.getItem("GuestOk"));
          if(session.getItem("city")==="Thoury" || session.getItem("city") ==="Massives"|| session.getItem("city") ==="Saint-Dyé-sur-Loire") {
            setMinOrderPrice(20)
          }
      if( session.getItem("city") ==="Lailly-en-Val"  || session.getItem("city")==="La Ferté-Saint-Cyr" ||session.getItem("city")==="Mer"|| session.getItem("city")==="Maslives"|| session.getItem("city")==="Chambord") {
        setMinOrderPrice(30)
      }
      if(session.getItem("city") ==="Dhuizon"|| session.getItem("city")==="Montlivault"){
        setMinOrderPrice(40)
      }
        }
            // Fetch drinks from the 'items' collection with category 'boissons'
    db.collection('items')
    .where('category', '==', 'Boissons')
    .get()
    .then((data) => {
      const drinksData = data.docs.map((doc) => doc.data());
      setDrinks(drinksData.filter(t=>(t.name!=="Maxi Cola"&&t.name!=="Maxi Fanta"&&t.name!=="Oasis 2L")));
      setDrinks2(drinksData);
    });
        },[]);
        function searchitems(x){
   
          if(x.length!==0){
            db.collection('items')
            .where("name", ">=",  x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
            .where("name", "<=", x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()+ "\uf8ff")
            .where("category", "==", "Pizza")
            .get()
            .then((data) => {
              return setItems(data.docs);
            });
          }
          if(x.length===0){
              db.collection("items").where("category","==","Pizza")
        .get().then((data) => {
          return setItems(data.docs);
        });
          }
          if(pizzas >= (menuCountMap[foods.name] || 2) ){
              setItems([]);
          }
       
      }
      const handleFirstChoiceChange = (event) => {
        setChoice(event.target.value);
      };
      const handleSecondChoiceChange = (event) => {
        setChoice2(event.target.value);
      };
    
      const handleDrink1Change = (event) => {
        setSelectedDrink1(event.target.value);
      };
      const handlePizza1Change = (event) => {
        setSelectedPizza1(event.target.value);
      };
    
      const handleDrink2Change = (event) => {
        setSelectedDrink2(event.target.value);
      };
      const handlePizza2Change = (event) => {
        setSelectedPizza2(event.target.value);
      };
      const handleDrink3Change = (event) => {
        setSelectedDrink3(event.target.value);
      };
      const handlePizza3Change = (event) => {
        setSelectedPizza3(event.target.value);
      };
      const handleDrink4Change = (event) => {
        setSelectedDrink4(event.target.value);
      };
      
    
   
    function checkTopping(index){
        const newToppings = [...toppings];
        newToppings[index].checked = !newToppings[index].checked;
        setToppings(newToppings)
    }

    function close() {
           setOpenFood();
    }
    if (!openFood) return null ;
  //promoPrice ? (foods.size ? size+". "+foods.name+" "+promoPrice+"%":foods.name+" "+promoPrice+"%") :
  //promoPrice ? (((foods.size &&(size === "30CM")) ?foods.price1:foods.price)-((foods.size ? foods.price1:foods.price)*promoPrice/100)):
 
  const order = {
        ...foods,
        name :   (foods.size && foods.category==="Pizza") ? (size+". "+foods.name):(foods.size && foods.category==="Paninis") ?(size2+".Panini "+foods.name):foods.category==="Burger"? ("Burger "+foods.name): foods.category === "Menu"?foods.name : foods.name,
        price : foods.action ?foods.actionPrice : (foods.category==="Pizza" ?(foods.size &&size === "Méga" ? foods.price2:foods.size &&size==="Sénior"?foods.price1:foods.price):(foods.category==="Paninis"||foods.category==="Tacos"||foods.category==="Burger"||foods.category==="Zap'Wichs"||foods.category==="Galette")?(size2==="Menu"?foods.price1:foods.price):foods.price),
        Qu:quantity.value,
        toppings: toppings,
        choice: choiceRadio.value,
        remark: foods.category === "Menu" ? withoutItemMessage2 ? (remark ? remark + " | " + withoutItemMessage2 : withoutItemMessage2) : remark : withoutItemMessage ? (remark ? remark + " | " + withoutItemMessage : withoutItemMessage) : remark,
        foodType : foods.name === "Menu Duo" ? "Une Pizza "+ selectedPizza1 :foods.name === "Menu Trio" ? "Pizza .J "+selectedPizza1+" Pizza .J "+selectedPizza2+ " Pizza .J "+selectedPizza3 :foods.category ==="Menu"&&foods.name !=="Menu Enfant" ? " Pizza "+selectedPizza1+" Pizza "+selectedPizza2: null,
        selectedChoice : (size2==="Menu"||foods.category==="Menu") ? ("Choix : "+ Choice + (foods.name ==="Menu Enfant" ? Choice2:"")) :"",
        drinks :(size2==="Menu" ||foods.category==="Menu" )? "Boissons : "+(foods.name==="Menu Enfant"?"Capri-Sun":"")+ selectedDrink1 +" "+selectedDrink2+" "+selectedDrink3+" "+ selectedDrink4 :"",
        sauces : "Sauces : " + sauce1 + " "+ sauce2 + " "+ sauce3,
        viande : (foods.category === "Tacos" ||foods.category === "bowls")? "Viande : "+ viande1 + " "+viande2+ " "+viande3 : ""
      };
    
    window.addEventListener('beforeunload', function() {
      // Clear the value in session storage
      sessionStorage.setItem('lastCheapest', null);
    });
    window.addEventListener('DOMContentLoaded', function() {
      // Clear the value in session storage
      sessionStorage.setItem('lastCheapest', null);
    });
    function addToOrder(){
  orders.push(order)
  console.log(orders)
 
      
    
  
   //check if there is more than 1 item in the cart
 
    if(orders.filter(i=> i.category===promoCategory).length>1){
      
   
   var Indexes = orders.filter(i=> i.category===promoCategory).sort((a,b)=>b.price-a.price).map(t=>orders.indexOf(t));
   //so the cheapest will be with index 0 
   var cheapestItmeIndex =  Indexes[Indexes.length-1];
      console.log(session.getItem("lastCheapest")+"   "+cheapestItmeIndex)

    if(session.getItem("lastCheapest") !==null && orders[cheapestItmeIndex].Qu>1&&orders.filter(x=>x.category==promoCategory).length>2){
      // nothing to do 
      console.log("nothing to do ")
    }
    else if(session.getItem("lastCheapest") || session.getItem("lastCheapest")!== cheapestItmeIndex){
      console.log("doing")
//if there is already promotion putting back to normal
if(orders[cheapestItmeIndex].name.includes("%")){
  orders[cheapestItmeIndex].name = orders[cheapestItmeIndex].name.slice(0,-4);
  orders[cheapestItmeIndex].price = (orders[cheapestItmeIndex].price/(1-Number(promoPrice)/100))/orders[cheapestItmeIndex].Qu;
}

//searching the cheapest
for(let i =1 ;i<Indexes.length;i++){
 if(orders[Indexes[i]].price/orders[Indexes[i]].Qu<(orders[cheapestItmeIndex].price/orders[cheapestItmeIndex].Qu)){
   cheapestItmeIndex = Indexes[i]
 }

}

//apply changes to cheapest 
orders[cheapestItmeIndex].price = Math.round((orders[cheapestItmeIndex].price - ((orders[cheapestItmeIndex].price*Number(promoPrice)/100)/orders[cheapestItmeIndex].Qu)));
orders[cheapestItmeIndex].name =  orders[cheapestItmeIndex].name +" "+promoPrice+"%";

session.setItem("lastCheapest",cheapestItmeIndex); 
    }
  
   
}
   // save the cheapest index to modify it later 
  


         setOrders(orders);
         /******/
         const docRef = db.collection("items").where("name","==",order.name);

         docRef.get().then((querySnapshot)=>{
          querySnapshot.forEach((doc)=>{
           doc.ref.update({
             quantity: doc.data().quantity >0 ?  doc.data().quantity - order.Qu : 0
           })
          })
         });
          /*****/
         setPromoPrice();
         close();
    }




    function editOrder(){
        const newOrders = [...orders];
        newOrders[openFood.index] = order;
               //need some tweaking 
             /******/
             const docRef = db.collection("items").doc(order.name);
             docRef.get().then((data)=>{
               if(quantity.value > order.Qu){
               docRef.update({
                 quantity: data.data().quantity >0 ?  data.data().quantity - (quantity.value - order.Qu) : 0
               })
              }
              else if( quantity.value < order.Qu){
                docRef.update({
                  quantity: data.data().quantity + (order.Qu - quantity.value ) 
                })
              }
              });
              /*****/
        setOrders(newOrders);
        close();
    }

    return (
        <>
        <DialogShadow onClick={close} />
        <Wrapper>
          
        <Dialog>
           
            <Close  onClick={()=>{close(); setOrderEdit(false) }}/>
        <DialogBanner img={"https://firebasestorage.googleapis.com/v0/b/"+Id+".appspot.com/o/files%2F"+foods.imgUrl+"?alt=media&token="+Token}>
        <DialogBannerName>{foods.name}</DialogBannerName>
        </DialogBanner>
        <DialogContent>
        {/*Choice of Burger meat */}
       
       
       {foods.size && foods.category ==="Pizza"? <div>
       <HGrid>
  {
    foods.ingredient.split(', ').map((x, index) =>
      <HDiv key={index}>
         <input
              type="checkbox"
              id={`checkbox-${index}`}
              checked={checkedItems[index]}
              onChange={() => handleCheckboxChange(index)}
              style={{ marginRight: '5px' }}
            />
            <label htmlFor={`checkbox-${index}`}>{x}</label>
            
      </HDiv>
    )
  }
 
</HGrid>
{withoutItemMessage && <div>{withoutItemMessage}</div>}
</div>:""}
{foods.size && foods.category ==="Pizza"?<div>
        <Radio   
     type="radio" 
     value="Junior"
    
     checked={size==="Junior" ? "checked":""}
   onChange={(e)=>{console.log(foods.size);setSize(e.target.value)}}  
            />
         <RadioLabel>Junior</RadioLabel>

   <Radio   
     type="radio" 
     value="Sénior"
    
     checked={size==="Sénior" ? "checked":""}
   onChange={(e)=>{console.log(foods.size);setSize(e.target.value)}}  
            />
         <RadioLabel>Sénior</RadioLabel>

          <Radio   
         type="radio" 
         value="Méga"
     
      checked={size==="Méga"? "checked":""}
     onChange={(e)=>{setSize(e.target.value)}}  
   />
   <RadioLabel>Méga</RadioLabel>

</div>:foods.size && foods.category !=="Desserts" && foods.category !=="Boissons"&& foods.category !=="Pizza"&&foods.category!=="Menu"?
<div>
<Radio   
     type="radio" 
     value="Seul"
    
     checked={size2==="Seul" ? "checked":""}
   onChange={(e)=>{console.log(foods.size);setSize2(e.target.value)}}  
            />
         <RadioLabel>Seul</RadioLabel>

   <Radio   
     type="radio" 
     value="Menu"
    
     checked={size2==="Menu" ? "checked":""}
   onChange={(e)=>{console.log(foods.size);setSize2(e.target.value)}}  
            />
         <RadioLabel>Menu</RadioLabel>
</div>
:""}
{ (size2==="Menu" || size2==="Seul")&& (foods.category !=="Desserts" && foods.category !=="Boissons"&& foods.category !=="Pizza"&&foods.category!=="Menu")&&
 <div>
 <Label>
   Sauce 1:
   <select style={{"marginLeft":"5px"}}  value={sauce1} onChange={(e) => handleDropdownChange(e, setSauce1)}>
     <option value="">Select Sauce</option>
     {saucesData.map((sauce) => (
       <option key={sauce.id} value={sauce.name}>
         {sauce.name}
       </option>
     ))}
   </select>
 </Label>

 <br />

 <Label>
   Sauce 2:
   <select style={{"marginLeft":"5px"}} value={sauce2} onChange={(e) => handleDropdownChange(e, setSauce2)}>
     <option value="">Select Sauce</option>
     {saucesData.map((sauce) => (
       <option key={sauce.id} value={sauce.name}>
         {sauce.name}
       </option>
     ))}
   </select>
 </Label>

 <br />

 <Label>
   Sauce 3:
   <select style={{"marginLeft":"5px"}}  value={sauce3} onChange={(e) => handleDropdownChange(e, setSauce3)}>
     <option value="">Select Sauce</option>
     {saucesData.map((sauce) => (
       <option key={sauce.id} value={sauce.name}>
         {sauce.name}
       </option>
     ))}
   </select>
 </Label>
 {(foods.category === "Tacos"||foods.category === "bowls")&&
 
 <div>
{(foods.name ==="M. 1 Viande"|| foods.name === "B1 viande")&&<div>
<Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande1} onChange={(e) => handleDropdownChange(e, setViande1)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
  </div>}
{(foods.name ==="L. 2 Viande"||foods.name === "B2 viandes")&& <div>
<Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande1} onChange={(e) => handleDropdownChange(e, setViande1)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
 <Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande2} onChange={(e) => handleDropdownChange(e, setViande2)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
  </div>}
{(foods.name ==="XL. 3 Viande"||foods.name === "B3 viandes")&& <div>
<Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande1} onChange={(e) => handleDropdownChange(e, setViande1)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
 <Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande2} onChange={(e) => handleDropdownChange(e, setViande2)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
 <Label>
   Viande :
   <select style={{"marginLeft":"5px"}}  value={viande3} onChange={(e) => handleDropdownChange(e, setViande3)}>
     <option value="">Choisir viande</option>
     {viandeData.map((viande) => (
       <option key={viande.id} value={viande.name}>
         {viande.name}
       </option>
     ))}
   </select>
 </Label>
  </div>}
 </div>
 }
 { size2==="Menu" &&
 <div>
 <Label>
     Boissons :
     <select style={{"marginLeft":"5px"}}  value={selectedDrink1} onChange={handleDrink1Change}>
       <option value="">Selectionner Boisson </option>
       {drinks.map((drink) => (
         <option key={drink.id} value={drink.name}>
           {drink.name}
         </option>
       ))}
     </select>
   </Label>
  

   <Label>
     <input
       type="radio"
       value= "Frite"
       checked={Choice === 'Frite'}
       name="menuChoice2"
       onChange={handleFirstChoiceChange}
     />
    Frite
   </Label>
   <Label>
     <input
       type="radio"
       value= "Patatos"
       checked={Choice === 'Patatos'}
       name="menuChoice2"
       onChange={handleFirstChoiceChange}
     />
     Patatos
   </Label>
   </div>
 }

   
</div>}

{/*Choice for menu */}
{foods.category === "Menu" &&  
 
 <div>
  {/* Menu Duo */}
  {
    foods.name ==="Menu Duo"&&
    <Label>
    Pizza:
    <select style={{"marginLeft":"5px"}} value={selectedPizza1} onChange={handlePizza1Change}>
      <option value="">Selectionner Pizza </option>
      {pizzasList.map((p) => (
        <option key={p.id} value={p.name}>
          {p.name}
        </option>
      ))}
    </select>
  </Label>
  }

  {/* Menu Trio */}
  {
    foods.name ==="Menu Trio"&&
    <>
    <Label>
    Pizza:
    <select style={{"marginLeft":"5px"}} value={selectedPizza1} onChange={handlePizza1Change}>
      <option value="">Selectionner Pizza </option>
      {pizzasList.map((p) => (
        <option key={p.id} value={p.name}>
          {p.name}
        </option>
      ))}
    </select>
  </Label>
      <Label>
      Pizza:
      <select style={{"marginLeft":"5px"}} value={selectedPizza2} onChange={handlePizza2Change}>
        <option value="">Selectionner Pizza </option>
        {pizzasList.map((p) => (
          <option key={p.id} value={p.name}>
            {p.name}
          </option>
        ))}
      </select>
    </Label>
        <Label>
        Pizza:
        <select style={{"marginLeft":"5px"}} value={selectedPizza3} onChange={handlePizza3Change}>
          <option value="">Selectionner Pizza </option>
          {pizzasList.map((p) => (
            <option key={p.id} value={p.name}>
              {p.name}
            </option>
          ))}
        </select>
      </Label>
      </>
    
  }

  {/* Menu Quatro */}
  {
   (foods.name ==="Menu Quatro"|| foods.name==="Menu Géant") &&
    <>
        <Label>
    Pizza:
    <select style={{"marginLeft":"5px"}} value={selectedPizza1} onChange={handlePizza1Change}>
      <option value="">Selectionner Pizza </option>
      {pizzasList.map((p) => (
        <option key={p.id} value={p.name}>
          {p.name}
        </option>
      ))}
    </select>
  </Label>
  <Label>
    Pizza:
    <select style={{"marginLeft":"5px"}} value={selectedPizza2} onChange={handlePizza2Change}>
      <option value="">Selectionner Pizza </option>
      {pizzasList.map((p) => (
        <option key={p.id} value={p.name}>
          {p.name}
        </option>
      ))}
    </select>
  </Label>
    </>

  }

 {/* First Choice - Radio Button */}
 {(foods.name === "Menu Duo" || foods.name ==="Menu Géant") &&<div>
   <Label>
     <input style={{"marginRight":"5px"}}
       type="radio"
       value= {foods.name === "Menu Duo" ? "6 wings " : foods.name === "Menu Géant" ? "10 wings ": null}
       checked={Choice ===(foods.name === "Menu Duo" ? "6 wings " : foods.name === "Menu Géant" ? "10 wings ": null)}
       name="menuChoice"
       onChange={handleFirstChoiceChange}
     />
    {foods.name === "Menu Duo" ? "6 wings " : foods.name === "Menu Géant" ? "10 wings ": null}
   </Label>
   <Label>
     <input style={{"marginRight":"5px"}}
       type="radio"
       value= {foods.name === "Menu Duo" ? "6 nuggets " : foods.name === "Menu Géant" ? "10 nuggets ": null}
       checked={Choice === (foods.name === "Menu Duo" ? "6 nuggets " : foods.name === "Menu Géant" ? "10 nuggets ": null)}
       name="menuChoice"
       onChange={handleFirstChoiceChange}
     />
     {foods.name === "Menu Duo" ? "6 nuggets " : foods.name === "Menu Géant" ? "10 nuggets ": null}
   </Label>
 </div> }




 {foods.name === "Menu Enfant" &&<div>
   <Label>
     <input style={{"marginRight":"5px"}}
       type="radio"
       value= {"Cheese"}
       checked={Choice2 ==="Cheese"}
       name="menuChoice"
       onChange={handleSecondChoiceChange}
     />
    {"Cheese"}
   </Label>
   <Label>
     <input style={{"marginRight":"5px"}}
       type="radio"
       value= {"6 nuggets "}
       checked={Choice2 ==="6 nuggets "}
       name="menuChoice"
       onChange={handleSecondChoiceChange}
     />
     {"6 nuggets "}
   </Label>
 </div> }

















 {/* Second Choice - Dropdown Lists */}
 <div>
   {foods.name ==="Menu Enfant" ? <>Boissons : Capri-Sun</>: <Label>
     Boissons 1:
     <select style={{"marginLeft":"5px"}} value={selectedDrink1} onChange={handleDrink1Change}>
       <option value="">Selectionner Boisson </option>
       {drinks2.map((drink) => (
         <option key={drink.id} value={drink.name}>
           {drink.name}
         </option>
       ))}
     </select>
   </Label>  }
{ (foods.name === "Menu Quatro" || foods.name ==="Menu Duo")&&
<Label>
Boissons 2:
<select style={{"marginLeft":"5px"}} value={selectedDrink2} onChange={handleDrink2Change}>
  <option value="">Selectionner Boisson </option>
  {drinks2.map((drink) => (
    <option key={drink.id} value={drink.name}>
      {drink.name}
    </option>
  ))}
</select>
</Label>

}


{ foods.name === "Menu Quatro" &&
<div>
<Label>
Boissons 3:
<select style={{"marginLeft":"5px"}} value={selectedDrink3} onChange={handleDrink3Change}>
  <option value="">Selectionner Boisson </option>
  {drinks2.map((drink) => (
    <option key={drink.id} value={drink.name}>
      {drink.name}
    </option>
  ))}
</select>
</Label>

<Label>
Boissons 4:
<select style={{"marginLeft":"5px"}} value={selectedDrink4} onChange={handleDrink4Change}>
  <option value="">Selectionner Boisson </option>
  {drinks2.map((drink) => (
    <option key={drink.id} value={drink.name}>
      {drink.name}
    </option>
  ))}
</select>
</Label>
</div>
}

 </div>
</div>
 
 }


{ foods.category ==="Menu" ?
<div>

         
           
      
  
 {/* Panel to display detailed information about the selected pizza */}

</div> : ""}

            <HContainer>

          {foods.category !== "Menu" ?<QuantityInput quantity ={quantity} openFood={foods} lang={lang} applied={applied}/> : ""}  


            {(foods.category != "SZÓSZOK" &&foods.category != "ITALOK") ? <VContainer>
             
            {foods.category !== "Boissons" ? <Remark remark={remark.substring(0,99)} setRemark={setRemark} lang={lang}/> : "" }
            {foods.category !== "Boissons" ?  <Limit>{remark.length != 0 ?(100-remark.length) : "100"}</Limit>: "" }</VContainer> :"" }
            </HContainer>

            
           
         {toppings.length == 0 || foods.category === "Boissons" || foods.category === "Menu"? "" :  <H4>{T("66",lang)} :  {order.toppings.filter(t=> t.checked).reduce((acc,obj)=>{return acc + obj.price},0)===0 ? "":order.toppings.filter(t=> t.checked).reduce((acc,obj)=>{return acc + obj.price},0)+" €"}</H4> } 
         {toppings.length == 0 || foods.category === "Boissons"|| foods.category === "Menu"? "" : <Toppings toppings={toppings} checkTopping= {checkTopping} lang={lang}/>  } 
      
 
           
           

           {openFood.choices && <Choices openFood={openFood} />}
        </DialogContent>
        <DialogFooter>
             <ConfirmButton onClick={isOrderedit ? editOrder : addToOrder}
              disabled={foods.category === "Tacos" && viande1 == ''}>

                 {isOrderedit ? T("67",lang)+(foods.size ? getPrice2(order,foods.category ==="Pizza"?size:size2): (getPrice(order)))+" €": (T("68",lang)+(foods.size ? getPrice2(order,foods.category ==="Pizza"?size:size2): (getPrice(order)))+" €")}
            </ConfirmButton>
        </DialogFooter>

        </Dialog>
        </Wrapper>
       </>
    );
 
}

export function FoodDialog(props){
    if (!props.openFood) return null;
    return <FoodDialogContainer {...props}/>
}