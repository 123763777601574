// StyledComponents.js

import styled from 'styled-components';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
`;

export const RoomsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  overflow-y: scroll;
  background-color: #060b26;
  padding: 10px 5px 35px 5px;
  height: 100%;
  position: fixed;
  right: ${props => props.isVisible ? '0' : '-250px'};
  transition: right 0.3s ease;
  @media (max-width: 600px) {
    opacity: 0.8;
  }
`;

export const RoomFrame = styled.div`
  color: #04646c;
  margin: 2px;
  height: 40px;
  padding-left: 5px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.02);
    background-color: gray;
    border-radius: 3px;
  }
`;

export const OrderNotif = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
`;

export const DialogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Dialog = styled.div`
  width: 500px;
  height: auto;
  background-color: white;
  margin: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  @media (max-width: 600px) {
    margin-left: 40px;
    overflow-y: scroll;
    height: auto;
    width: auto;
  }
`;

export const ProfileContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
`;

export const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isUser ? 'flex-start' : 'flex-end')};
  margin-bottom: 15px;
`;

export const Box = styled.div`
  background-color: ${props => (props.isUser ? '#060b26' : '#e0e0e0')};
  color: ${props => (props.isUser ? 'white' : 'black')};
  border-radius: 8px;
  padding: 10px;
  max-width: 70%;
  word-wrap: break-word;
`;

export const Time = styled.div`
  font-size: 12px;
  color: gray;
  margin-top: 5px;
  align-self: ${props => (props.isUser ? 'flex-start' : 'flex-end')};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  width: 90%;
`;

export const TextArea = styled.textarea`
  resize: none;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid gray;
`;

export const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #A5BF36;
  opacity: 0.8;
  z-index: 4;
  ${({ disabled }) => disabled && `
    opacity: .5;
    background-color: grey;
    pointer-events: none;
  `}
`;

export const HamburgerButton = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 60;
  border-radius: 5px;
`;
