import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import { NavbarBackgroundColor } from '../Styles/colors';
import { NavLyric } from '../Styles/colors';
import { Outlet,Link } from 'react-router-dom';
import {T} from "../Language/translate";
import './CatNav.css'
//Pacifico 
const Span = styled.span`
position:relative;
font-weight:300;
color:${NavLyric};
font-size:14px;
@media(max-width: 1260px){ 
  font-size:14px;
 
}
@media(max-width: 1130px){ 
  font-size:14px;
 
}
@media(max-width: 995px){ 
  color:white !important;
 
}
@media(max-width: 880px){ 
  font-size:12px;
 
}
@media(max-width: 722px){ 
  vertical-align: middle !important;
 
  font-size:10px;
}
@media(max-width: 500px){ 
  font-weight:900 !important;
  vertical-align: middle !important;
  color:white !important;
  font-size:8px;
 
}

`;
const Container = styled.div`
position:fixed;
width:calc(100% - 340px);

left:0;
top:0px;
z-index:55;

@media(max-width: 700px){ 
  top:65px;
  width:100%;
}
`;
const Nav = styled.nav`
display:flex;
width: 100%;
justify-content: center;
align-items: center;
background-color: ${NavbarBackgroundColor};
padding-bottom:30px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
@media(max-width: 1028px){ 

  
  padding-bottom:30px;
  width:auto;
  @media(max-width: 700px){ 
    opacity: .7;
    padding-bottom:5px;
   
    width:auto;
    
  }
}
@media(max-width: 450px){ 

opacity: .7;
 
  width:auto;
  
}
@media(max-width: 340px){ 
  
 opacity:.7;
  
}
`;
const Li = styled.li`
display: flex;
width: auto;
text-align: center;
justify-content: center;
align-items: center;
margin-left: 10px;
margin-right: 10px;
border-radius: 7px;
&:hover {
  background-color: lightgray;
    opacity:0.5;
    border-radius: 7px;
}
box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
transform: ${props => props.scale };
border : ${props => props.border };
border-radius:7px;
@media (max-width: 700px){

      border-bottom: ${props => props.border + " "+props.phoneColor };
}
`; 
const db = window.firebase.firestore();
function CatNav({lang}) {
    const [data , setData] = useState();
    const[category,setCategory] = useState("Pizza");
    const[lyricColor,setLyricsColor] =useState("");
    
    const canShowCategory = data && true;
    useEffect(()=>{ 
        db.collection('Category').onSnapshot((data)=>{return  setData(data.docs)});
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setLyricsColor(doc.data().navLyric)});
        },[]);
  return (
    <>

  
<Container>
  <Nav className="navbar navbar-expand-lg navbar-light " 
  phoneBackgroundColor="#F8F1DE"
  >
  {/*<button className=" navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span className="buttonCat">{T("102",lang)}</span>
  </button>*/}
 {category?"":""}
  <div className=" subNav" id="navbarNavAltMarkup" data-toggle={window.screen.width <= 700 ? "collapse" : ""} data-target=".navbar-collapse">
    <div className="navbar-nav navMain">
    {category? "":""}
      {  canShowCategory ? data.sort((a,b) => a.data().index - b.data().index).map((item, index)=>{
          return (
            <Li scale={category == item.data().name ? "scale(1.10)":"scale(1)"}
             border={category == item.data().name ? "2px solid orange": "none"} 
             phoneColor={category == item.data().name ? "red": ""}
             key={index} className= "nav-item nav-link " > 
              <Link className='link'  to={item.data().path}  onClick={()=>setCategory(item.data().name)} > 
                <Span>{T(item.data().langIndex,lang)}</Span>
              </Link>
            </Li>
          )
        }) :""}
    </div>
  </div>

</Nav>
</Container>
<Outlet />

</>

 
    
  )
}

export default CatNav
