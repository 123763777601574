import React, { useState, useEffect, useRef } from "react";
 
import {
  SupportContainer,
  RoomsList,
  RoomFrame,
  OrderNotif,
  DialogContainer,
  Dialog,
  ProfileContainer,
  MsgContainer,
  Box,
  Time,
  InputContainer,
  TextArea,
  ConfirmButton,
  HamburgerButton,
} from "./Styles/StyledComponents";

const db = window.firebase.firestore();
var session = window.sessionStorage;

function Support({ loggedIn, lang }) {
  const [text, setText] = useState("");
  const [Smessages, setSmessages] = useState([]);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [rooms, setRooms] = useState([]);
  const [isRoomsListVisible, setIsRoomsListVisible] = useState(true);
  const profileContainerRef = useRef(null);

  // Track the last message's timestamp
  const lastMessageTimestamp = useRef(0);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    const unsubscribeRooms = db.collection("support").onSnapshot((data) => {
      setRooms(data.docs);
    });

    if (isValidEmail(email)) {
      db.collection("users")
        .where("email", "==", email)
        .get()
        .then((data) => {
          setUser(data.docs[0].data());
        });
    } else {
      db.collection("guests")
        .where("phone", "==", email)
        .get()
        .then((data) => {
          setUser(data.docs[0].data());
        });
    }

    // Cleanup function
    return () => unsubscribeRooms();
  }, [email]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  // Scroll to the bottom when Smessages changes
  useEffect(() => {
    if (profileContainerRef.current) {
      profileContainerRef.current.scrollTop =
        profileContainerRef.current.scrollHeight;
    }
  }, [Smessages]);

  function showNotification(message) {
    if (Notification.permission === "granted") {
      new Notification("New Message", {
        body: message,
      });
    }
  }

  function LoadRoom(email) {
    const unsubscribeMessages = db.collection("support")
      .where("email", "==", email)
      .onSnapshot((snapshot) => {
        const doc = snapshot.docs[0];
        const messages = doc ? doc.data().messages : [];
        setSmessages(messages);

        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          // Check if this is a new message
          if (lastMessage.timestamp > lastMessageTimestamp.current && lastMessage.sender === "user") {
            showNotification(lastMessage.value);
            lastMessageTimestamp.current = lastMessage.timestamp; // Update the last message timestamp
          }
        }
      });

    // Cleanup function
    return () => unsubscribeMessages();
  }

  function sendMessage() {
    const newMessages = [
      ...Smessages,
      {
        value: text,
        date: `${new Date().getMonth() + 1}/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`,
        sender: "support",
        timestamp: Date.now(), // Add timestamp for comparison
      },
    ];
  
    const docRef = db.collection("support").doc(email);
  
    docRef.get().then((doc) => {
      if (doc.exists) {
        // The document exists, update the messages field and email only
        docRef.set({
          email: email,
          messages: newMessages,
        }, { merge: true });
      } else {
        // The document does not exist, set email, messages, and createdAt fields
        docRef.set({
          email: email,
          messages: newMessages,
          createdAt: new Date(), // Set createdAt for new document
        });
      }
    });
  
    setText("");
  }
  

  return (
    <SupportContainer>
      <HamburgerButton onClick={() => setIsRoomsListVisible(!isRoomsListVisible)}>
        ☰
      </HamburgerButton>
      <RoomsList isVisible={isRoomsListVisible}>
        {rooms.length ? (
          rooms.map((room, index) => (
            <RoomFrame
              key={index}
              onClick={() => {
                LoadRoom(room.data().email);
                setEmail(room.data().email);
              }}
            >
              {room.data().email}
              {room.data().messages &&
                room.data().messages[room.data().messages.length - 1].sender === "user" && <OrderNotif>1</OrderNotif>}
            </RoomFrame>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </RoomsList>
      <DialogContainer>
       
        <Dialog>
          {user && (
            <>
              <div>{"Name: " + user.name}</div>
              <div>{"Phone: " + user.phone}</div>
              <div>{"Zip : " + user.zipCode}</div>
              <div>
                {"Address: " +
                  user.city +
                  " " +
                  user.street +
                  " " +
                  user.houseNumber +
                  " " +
                  user.floor +
                  "/" +
                  user.door}
              </div>
            </>
          )}
          {Smessages.length ? (
            <ProfileContainer ref={profileContainerRef}>
              {Smessages.map((t, index) => (
                <MsgContainer key={index} isUser={t.sender === "user"}>
                  <Box
                    isUser={t.sender === "user"}
                    dangerouslySetInnerHTML={{ __html: t.value }}
                  ></Box>
                  <Time isUser={t.sender === "user"}>{t.date}</Time>
                </MsgContainer>
              ))}
            </ProfileContainer>
          ) : null}
          {Smessages.length ? (
            <>
              <InputContainer>
                <TextArea
                  type="text"
                  placeholder="Message"
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                />
              </InputContainer>
              <InputContainer>
                <ConfirmButton
                  disabled={text.length === 0}
                  onClick={sendMessage}
                >
                  Send
                </ConfirmButton>
              </InputContainer>
            </>
          ) : null}
        </Dialog>
      </DialogContainer>
    </SupportContainer>
  );
}

export default Support;
