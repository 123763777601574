import React from "react";
import styled, { keyframes } from "styled-components";
import { FoodGrid ,Food  } from "../../FoodGrid";
import { useState ,useEffect} from "react"
import ModelLoading from "../../../Loading/ModelLoading";
import { OrderBtn } from "../../../Styles/colors";
import {Id} from "../../../ProjectId";
import { Token } from "../../../StorageToken";
var _ = require('lodash');
const MenuStyled = styled.div`
z-index:25;
position:relative;
 height: "auto";
 margin: auto;
 @media(max-width: 400px){ 
    width: 100%; 
    margin: 5px; 
  }

`
const Descriptor = styled.div`
display:grid;
width:250px;
grid-template-rows: auto auto auto auto;
 padding-left:10px;
 padding-right:10px;
 @media(max-width: 1685px){ 
    width:170px;
  }
  @media(max-width: 1280px){ 
    width:170px;
  }
  @media(max-width: 1160px){ 
    width:170px;
  }
  @media(max-width: 780px){ 
    width:163px;
  }
   @media(max-width: 740px){ 
    width:130px;
  }
  @media(max-width: 700px){ 
    width:120px;
  }
  @media(max-width: 480px){ 
    width:150px;
  }
 
 
 
 
`;
const Div = styled.div`
     font-weight:bold;
     font-size:22px;
     white-space: nowrap;
     text-align:center;
     margin-top:-8px;
     @media(max-width: 1390px){ 
      white-space: normal;
    }
     @media(max-width: 700px){ 
      font-size:16px;
    }
    @media(max-width: 350px){ 
      font-size:14px;
    }
   
`;
const StrikeAction = styled.div`
color:red;
text-decoration:line-through;
font-weight:bold;
font-size:22px;   
`;
const DivAction = styled.div`
color:black !important;
font-weight:bold;
font-size:18px;
white-space: nowrap;
text-align:center;
@media(max-width: 1390px){ 
 white-space: normal;
}
@media(max-width: 700px){ 
 font-size:16px;
}
@media(max-width: 350px){ 
 font-size:14px;
}
`;
const ActionContainer = styled.div`
display:flex;
flex-direction:column;

`;
const Title = styled.div`
line-height: 1.1;
    font-weight:bold;
     text-align:center;
    margin-top:10px;
     @media(max-width: 1390px){ 
      white-space: normal;
    
    }
     @media(max-width: 700px){ 
      font-size:16px;
    
    }
    @media(max-width: 350px){ 
      font-size:14px;
    }
   
`;
const ItemContainer = styled.div`
background-color: white;
 position:relative;
 display:grid;
 grid-template-columns: .2fr 1fr auto;
margin-top:10px;
width:${props => props.width} ;
height:auto;
text-align:center;
font-size:20px;

transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
  cursor: pointer;
    box-shadow : 0px 0px 10px 0px grey;
}
@media(max-width: 1300px){ 
  width:390px;
  
}
@media(max-width: 1160px){ 
  width:460px;
  grid-template-columns: 0.8fr 1fr auto;
}
@media(max-width: 800px){ 
  width:auto;
  grid-template-columns: auto auto auto;
}

@media(max-width: 740px){ 
  width:350px;
  grid-template-columns: 1fr 1fr auto;
}

@media(max-width: 460px){ 
  width:370px;
  grid-template-columns: 1fr 0.80fr auto;
}
@media(max-width: 380px){ 
  width:305px;
  grid-template-columns: .4fr 1fr auto;
}
${({disabled})=>disabled && `
opacity: .5;
cursor: not-allowed;
pointer-events: none;
`}
`;
const Button = styled.div`
color:white;
background-image:url("/img/basket.png");
background-position: center;
background-repeat: no-repeat;
position:relative;
background-color:${OrderBtn};
background-size: 50px;
width:60px;
padding-top:5px;

opacity: 0.8;
text-align:center;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 7px;
box-shadow : 0px 0px 2px 0px grey;


&:hover{
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.05);
 
}
@media(max-width: 740px){ 
  width:58px;

}
@media(max-width: 460px){ 
  width:50px;

}
`;
const Button2 = styled.div`
color:white;
background-image:url("/img/basket.png");
background-position: center;
background-repeat: no-repeat;
position:relative;

background-color:${OrderBtn};
background-size: 50px;
width:70px;
height:70px;
padding-top:5px;
margin-top:auto;
margin-right:10px;
margin-bottom:10px;
opacity: 0.8;
text-align:center;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 50%;
box-shadow : 0px 0px 2px 0px grey;
@media(max-width: 700px){ 
  background-size: 40px;
width:60px;
height:60px;

}

&:hover{
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.05);
 
}
`;
const Ingredient = styled.div`
text-align:left;
margin-top:5px;
width:185px;
color:gray;
font-size:12px;

line-height: 1.1;
@media(max-width: 1390px){ 
  font-size:12px;
}
@media(max-width: 800px){ 
  font-size:12px;
  width:150px;
}
@media(max-width: 740px){ 
  font-size:11px;
  width:120px;
}
@media(max-width: 700px){ 
  font-size:11px;
 

}
@media(max-width: 380px){ 
  font-size:10px;
}

`;
const Logo = styled.div`
margin-top:auto;
margin-bottom:auto;
margin-left:5px;
 height: 20px;
 width: 20px;
 background-image:url("/img/coin.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 @media(max-width: 700px){ 
  height: 10px;
 width: 10px;
}
@media(max-width: 380px){ 
  
}
 
`;
const Points = styled.div`
position:absolute;
display:flex;
flex-direction:row;
justify-items:center;
align-content:center;
color:black;
border-radius:5px;
margin:1px;
bottom:0;
left:20px;
font-size:14px;
@media(max-width: 700px){ 
  font-size:12px;
}
@media(max-width: 380px){ 
  font-size:10px;
}

`;
const Text = styled.div`
font-size:18px;
margin-top:auto;
margin-bottom:auto;
`;
const DivHidden = styled.div`
display:none ; 
`;
function blinkingEffect(){
  return keyframes`
  50% {
    opacity:0.2;
  }`;
}
const Akcio = styled.div`
height: 200px;
width: 200px;
display :flex;
animation: ${blinkingEffect} 3s linear infinite;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Akcio.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:-30px;
left:-25px;
font-size: 18px;
font-weight:bold;
z-index:50;

`;
const DivH = styled.div`
display:flex;
flex-direction:row;
justify-content: space-between;
`;
const DivV = styled.div`
display:flex;
flex-direction:column;

`;
const DivText = styled.div`
font-size:13px;
`;
const db = window.firebase.firestore();
export function Model({setOpenFood ,openFood,category,lang,admins, loggedIn}){
  const isAdmin = loggedIn&& admins.some(u=>u.email ===loggedIn.email&&(u.role==="admin"||u.role==="pos"));
    const [data , setData] = useState();
    const[changeRate,setChangeRate]=useState();
    const canShowItems = data && changeRate&& true;
    useEffect(()=>{ 
        db.collection('items').where('category','==',category).get().then((data)=>{return  setData(data.docs)});
        db.collection('Fees').where('name','==',"Standard").onSnapshot((data)=>{return  setChangeRate(data.docs[0].data().rate)});
        console.log(category)
// Update the image URLs for all product documents
/*
const prefix = "_500x500.JPG"
db.collection('items').get().then(snapshot => {
  snapshot.forEach(doc => {
    const data = doc.data();
    const oldUrl = data.imgUrl.slice(0, -4);
    const newUrl = oldUrl+prefix ;
    db.collection('items').doc(doc.data().name).update({imgUrl: newUrl});
  });
}).catch(error => {
  console.error(error);
});*/


   },[category,setOpenFood]);
   
    return (
    <>
    
    <MenuStyled >
      
        
        <>
     
        <FoodGrid grid = { "1fr 1fr "}>
          {canShowItems &&<DivHidden>{lang}</DivHidden>}
        { canShowItems ? data.sort((a,b) => a.data().index - b.data().index).map((food,index) => (
          
        <ItemContainer key={index} onClick={()=>{setOpenFood(food);}} width = { "440px"}
        disabled={food.data().quantity!=0 &&!food.data().status!="passive"  ? false:true}>
        {food.data().action && <Akcio />}
        <Food  img={"https://firebasestorage.googleapis.com/v0/b/"+Id+".appspot.com/o/files%2F"+food.data().imgUrl+"?alt=media&token="+Token} /> 
         
           
           
   
            
          <Descriptor>

            <Title>{food.data().name}</Title>
    
           
            
            { food.data().action ? <ActionContainer>
            <StrikeAction><DivAction>{food.data().size ?food.data().price1+" €":food.data().price+" €"}</DivAction></StrikeAction>
            <Div>{food.data().actionPrice+" €"}</Div>
            </ActionContainer>:<Div>{food.data().size && food.data().category==="Pizza"?<DivH>
            <DivV><DivText>Junior</DivText><div>{food.data().price+"€"}</div></DivV>
            <DivV><DivText>Sénior</DivText><div>{food.data().price1+"€"}</div></DivV>
            <DivV><DivText>Méga</DivText><div>{food.data().price2+"€"}</div></DivV>
            </DivH>:food.data().size && (food.data().category===("Paninis")||food.data().category===("Tacos")||food.data().category===("Burger")||food.data().category===("Galette")||food.data().category===("Zap'Wichs"))?
            <DivH>
                   <DivV><DivText>Seul</DivText><div>{food.data().price+"€"}</div></DivV>
                        <DivV><DivText>Menu</DivText><div>{food.data().price1+"€"}</div></DivV>
            </DivH>
                   
                        
            :food.data().price+"€"}</Div>}
     
         
              <Ingredient>{lang == "Hu" ? food.data().ingredient :food.data().ingredientEn ?food.data().ingredientEn: ""  }</Ingredient>
            
    
                <Points><Text>{Math.round(food.data().price/changeRate)} 💎 </Text></Points> 
            </Descriptor> 
           <Button onClick={()=>{setOpenFood(food);}}></Button>
            </ItemContainer>
            )) : ""}
        </FoodGrid>
        </>
      
        
    </MenuStyled>
    </>
    )

}

